import React from 'react';
import { Modal } from 'react-bootstrap';
import closeIcon from 'images/close.svg';
import history from 'utils/history';
import { isVideo } from 'utils/utils';
import classes from './MintingItemSuccess.module.scss';

const MintingItemSuccess = ({
  show,
  handleClose,
  item = {},
  createNewItem,
  campaignId,
}) => (
  <Modal show={show} onHide={handleClose}>
    <div className={classes.container}>
      <a className={classes.btn} onClick={handleClose}>
        <img src={closeIcon} className={classes.closeIcon} alt='close' />
      </a>
      {item?.file?.file?.name && (
        <div className={classes.fileWrapper}>
          <img
            src={
              isVideo(item.file.file.name)
                ? item.thumb_image.src
                : item?.file?.src
            }
            className={classes.file}
            alt='file'
          />
        </div>
      )}

      <p className={classes.completed}>Completed!</p>
      <div className={classes.actions}>
        <a
          className='btn btnSecond mr10'
          onClick={() => {
            history.push(`/admin/campaign-items/${campaignId}`);
          }}
        >
          View all items
        </a>

        <a className='btn btnMain' onClick={createNewItem}>
          Create new item
        </a>
      </div>
    </div>
  </Modal>
);

export default MintingItemSuccess;
