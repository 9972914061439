import React from 'react';
import { formatStringToNumber } from 'utils/utils';
import { COLOR_MAPPING } from 'utils/constants';
import classes from './Item.module.scss';

const Item = ({ item, handleItemClick }) => (
  <div className={classes.container} onClick={() => handleItemClick(item)}>
    <div
      className={classes.nftType}
      style={{
        borderColor:
          COLOR_MAPPING[item.nft_type?.collor] || item.nft_type?.collor,
        color: COLOR_MAPPING[item.nft_type?.collor] || item.nft_type?.collor,
      }}
    >
      {item.nft_type?.label}
    </div>
    <div className={classes.imageWrapper}>
      <img
        src={`${
          item.image.indexOf('http') === -1
            ? `${process.env.REACT_APP_API_URL}${item.image}`
            : item.image
        }`}
        className={classes.image}
        alt='img'
      />
    </div>
    <div className={classes.rowBetween}>
      <p className={classes.itemName}>{item.name}</p>
      <div className={classes.items}>
        {formatStringToNumber(item.total_supply)} items
      </div>
    </div>
  </div>
);

export default Item;
