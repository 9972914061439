import React, { useState, useEffect } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import InputField from 'components/InputField';
import TextAreaField from 'components/TextAreaField';
import SelectField from 'components/SelectField';
import Button from 'components/Button';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import defaultAvatar from 'images/defaultAvatar.svg';
import * as Api from 'api/api';
import DropzonUploaderField from 'components/DropzonUploaderField';
import { isVideo } from 'utils/utils';
import { Modal } from 'react-bootstrap';
import classes from './CreateCollection.module.scss';
import ThumbImageField from './ThumbImageField';
import CreateCollectionForm from './CreateCollectionForm';

const mapStateToProps = createStructuredSelector({
  formState: (state) => getFormValues('CreateCollection')(state) || {},
});

const CreateCollection = ({ handleSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loadingSubCategories, setLoadingSubCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);

  const getCategories = async () => {
    try {
      setLoadingCategories(true);
      const result = await Api.get({
        url: '/category',
      });
      setCategories(result.data);
      setLoadingCategories(false);
    } catch (e) {
      setLoadingCategories(false);
    }
  };

  const getSubCategories = async () => {
    try {
      setLoadingSubCategories(true);
      const result = await Api.get({
        url: '/subcategory',
      });
      setSubCategories(result.data);
      setLoadingSubCategories(false);
    } catch (e) {
      setLoadingSubCategories(false);
    }
  };

  useEffect(() => {
    getCategories();
    getSubCategories();
  }, []);

  const handleCreateCollection = async (values) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description', values.description);
      formData.append('category', values.category.value);
      formData.append('sub_category_id', values.subCategory.value);
      formData.append('file', values.file.file, values.file.file.name);
      await Api.post({
        url: '/collection',
        data: {},
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const { formState } = useSelector(mapStateToProps);

  // console.log(formState.file);
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h2 className={classes.title}>Create a collection</h2>
        <div className={classes.row}>
          <form
            className={classes.left}
            onSubmit={handleSubmit(handleCreateCollection)}
          >
            <h4 className={classes.subTitle}>Upload file</h4>
            <Field name='file' component={DropzonUploaderField} maxFiles={1} />

            {formState.file?.file?.name &&
              isVideo(formState.file?.file?.name) && (
                <Field name='thumbImage' component={ThumbImageField} />
              )}

            <h4 className={classNames(classes.subTitle, classes.mt20)}>
              Detail
            </h4>
            <Field name='name' component={InputField} label='Name' h50 />
            <Field
              name='description'
              component={TextAreaField}
              label='Description'
              rows={10}
            />
            <div className={classes.row}>
              <div className={classes.col}>
                <Field
                  name='category'
                  component={SelectField}
                  label='Category'
                  h50
                  options={categories}
                  loading={loadingCategories}
                />
              </div>
              <div className={classes.col}>
                <Field
                  name='subCategory'
                  component={SelectField}
                  label='Sub Category'
                  h50
                  options={subCategories}
                  loading={loadingSubCategories}
                />
              </div>
            </div>
            <div className={classes.row}>
              <div className={classes.col}>
                <h4 className={classes.subTitle}>Supply</h4>
                <p className={classes.text}>
                  Number of editions that can be minted
                </p>
              </div>
              <div className={classes.col}>
                <Field
                  name='numbreOfEditions'
                  component={InputField}
                  h50
                  options={[]}
                  placeholder='Eg: 5'
                />
              </div>
            </div>

            <Button
              className={classNames('btn btnMain w100 mt40', classes.mt40)}
              type='submit'
              loading={loading}
            >
              Create collection
            </Button>
          </form>
          <div className={classes.right}>
            <div className={classes.imageWrapper}>
              {formState.file?.file?.name && (
                <>
                  {isVideo(formState.file.file.name) &&
                    formState.thumbImage?.file.name && (
                      <img
                        src={formState.thumbImage?.src}
                        className={classes.image}
                        alt='preview'
                      />
                    )}
                  {!isVideo(formState.file.file.name) && (
                    <img
                      src={formState.file?.src}
                      className={classes.image}
                      alt='preview'
                    />
                  )}
                </>
              )}
            </div>
            <div className={classes.cardContent}>
              <p className={classes.name}>{formState.name}</p>
              <div className={classes.row}>
                <img
                  src={defaultAvatar}
                  alt='avatar'
                  className={classes.avatar}
                />
                <p className={classes.owner}>Dao Xuan Tung</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={false}>
        <CreateCollectionForm />
      </Modal>
    </div>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.file) {
    errors.file = 'Please upload a file';
  }

  if (!values.name) {
    errors.name = 'Please enter name';
  }

  if (!values.description) {
    errors.description = 'Please enter description';
  }

  if (!values.category) {
    errors.category = 'Please select category';
  }

  if (!values.subCategory) {
    errors.subCategory = 'Please select sub category';
  }

  if (!values.numbreOfEditions) {
    errors.numbreOfEditions = 'Please enter number of editions';
  }

  return errors;
};

export default reduxForm({
  form: 'CreateCollection',
  validate,
})(CreateCollection);
