import React, { Component } from 'react';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import classNames from 'classnames';
import Dropzone from 'react-dropzone-uploader';
import imageIcon from 'images/image-icon.svg';
import closeIcon from 'images/close.svg';
import { isVideo } from 'utils/utils';
import { connect } from 'react-redux';
import { showNotification } from 'layout/CommonLayout/actions';
import * as Api from 'api/api';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { renderField } from '../../Form';
// import { FileURL } from 'utils/config'
import classes from './DropzonUploaderField.module.scss';

class DropzonUploaderField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { input } = this.props;
    if (!input?.value && prevProps.input?.value) {
      this.handleRemoveDocument();
    }
  }

  getFilesFromEvent = (e) =>
    new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });

  getUploadParams = async ({ file }) => {
    const size = file.size;
    const { handleShowNotification } = this.props;
    const isVideoFile = isVideo(file.name);
    if (isVideoFile) {
      if (size > 150 * 1024 * 1024) {
        handleShowNotification({
          type: 'ERROR',
          message: 'Video file is so large > 150MB',
        });
        return;
      }
    }
    if (!isVideoFile && size > 5 * 1024 * 1024) {
      handleShowNotification({
        type: 'ERROR',
        message: 'Image file is so large > 5MB',
      });
      return;
    }
    const { input } = this.props;
    const { onChange } = input;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      onChange({ file, presrc: reader.result, loading: true });
      this.handleUpload(file);
    };
  };

  handleUpload = async (file) => {
    try {
      this.setState({
        loading: true,
      });

      const result = await Api.get({
        url: '/media/presign-upload',
        hideError: true,
      });

      const extend = file.name ? file.name.split('.').pop() : 'File';

      await axios.put(result.data.url, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': extend === 'svg' && 'image/svg+xml',
        },
      });

      const [imageUrl] = result.data.url.split('?');

      // console.log('response upload image', result.data.url)
      const { input } = this.props;
      input.onChange({ file, src: imageUrl, loading: false });
      this.setState({
        loading: false,
      });
    } catch (e) {
      // const { input } = this.props;
      // input.onChange(null);
      this.setState({
        loading: false,
      });
    }

    // const newValue = value || []
  };

  Preview = () => {
    const { input, disabled } = this.props;
    const { loading } = this.state;
    return (
      <div className={classes.preview}>
        {input.value && (
          <div className={classes.prev}>
            {!disabled && (
              <a
                className={classes.btnRemoveImage}
                onClick={this.handleRemoveDocument}
              >
                <img
                  src={closeIcon}
                  className={classes.closeIcon}
                  alt='close'
                />
              </a>
            )}

            {!isVideo(input.value?.file?.name) ? (
              <div className={classes.wrapper}>
                <img
                  src={input.value?.src || input.value?.presrc}
                  alt='preview-img'
                  className={classes.image}
                />
              </div>
            ) : (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video width='400' controls>
                <source src={input.value?.src || input.value?.presrc} />
              </video>
            )}
          </div>
        )}
        {loading && (
          <div className={classes.loader}>
            <Loader type='Oval' color='#DEB856' height={24} width={24} />
          </div>
        )}
      </div>
    );
  };

  Input = ({ accept, onFiles, files, getFilesFromEvent }) => {
    const text = files.length > 0 ? 'Add Document' : 'Select Document';
    const { maxFiles, input, imageOnly } = this.props;
    return (
      <div
        className={classNames(
          classes.inputComponent,
          maxFiles <= input.value.length && classes.pb0,
        )}
      >
        {this.Preview()}
        {!input.value && (
          <div className={classes.wrapper}>
            <img
              src={imageIcon}
              className={classes.imageIcon}
              alt='imageIcon'
            />
            <p className={classes.text}>Drag & drop file</p>
            <p className={classes.or}>or browse media on your device</p>
            <p className={classes.note}>
              JPEG, JPG, PNG, GIF, SVG. Maximum size: 5MB <br />
              {!imageOnly && ' MP4, WEBM. Maximum size: 150MB'}
            </p>
            <div className={classes.actions}>
              <label className='btn btnMain btnSmall'>
                {text}
                <input
                  style={{ display: 'none' }}
                  type='file'
                  accept={accept}
                  multiple
                  onChange={(e) => {
                    getFilesFromEvent(e).then((chosenFiles) => {
                      onFiles(chosenFiles);
                    });
                  }}
                />
              </label>
            </div>
          </div>
        )}
      </div>
    );
  };

  handleRemoveDocument = () => {
    this.dropzoneRef.handleRemove(this.dropzoneRef.files[0]);
    const { input } = this.props;
    input.onChange(null);
  };

  Layout = ({ input, dropzoneProps }) => (
    <div>
      <div {...dropzoneProps}>{input}</div>
    </div>
  );

  render() {
    const { maxFiles, hasError, accept } = this.props;
    return (
      <div
        className={classNames(classes.container, hasError && 'errorWrapper')}
      >
        <Dropzone
          inputContent='Drag and drop image here!'
          InputComponent={this.Input}
          LayoutComponent={this.Layout}
          getUploadParams={this.getUploadParams}
          multiple
          getFilesFromEvent={this.getFilesFromEvent}
          // PreviewComponent={null}
          maxFiles={maxFiles || 100}
          accept={accept || '.mp4,.avi,.webp,image/*'}
          ref={(ref) => (this.dropzoneRef = ref)}
          // onChangeStatus={this.handleChangeStatus}
        />
      </div>
    );
  }
}

const DropzonUploaderFieldContainer = connect(null, {
  handleShowNotification: showNotification,
})(DropzonUploaderField);

export default renderField(DropzonUploaderFieldContainer);
