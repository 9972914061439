import React, { useState, useEffect } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import InputField from 'components/InputField';
import TextAreaField from 'components/TextAreaField';
import SelectField from 'components/SelectField';
import Button from 'components/Button';
import classNames from 'classnames';
import { createStructuredSelector } from 'reselect';
import { useSelector } from 'react-redux';
import defaultAvatar from 'images/defaultAvatar.svg';
import * as Api from 'api/api';
import DropzonUploaderField from 'components/DropzonUploaderField';
import { isVideo } from 'utils/utils';
import arrowLeft from 'images/arrow-left.svg';
import { COLOR_MAPPING } from 'utils/constants';
import history from 'utils/history';
import classes from './MintingItem.module.scss';
import ThumbImageField from '../CreateCollection/ThumbImageField';
import MintingItemSuccess from './MintingItemSuccess';

const mapStateToProps = createStructuredSelector({
  formState: (state) => getFormValues('MintingItem')(state) || {},
});

const MintingItem = ({ handleSubmit, match, reset }) => {
  const [loading, setLoading] = useState(false);
  const [selectedMintingItem, setSelectedMintingItem] = useState(null);
  const [nftTypes, setNftTypes] = useState([]);
  const [campaign, setCampaign] = useState({});
  const [selectedNftType, setSelectedNftType] = useState();

  useEffect(() => {
    const getCampaignDetail = async (id) => {
      try {
        const [campaignResult, nftTypesResult] = await Promise.all([
          Api.get({
            url: `/admin/campaign/${id}`,
          }),
          Api.get({
            url: '/admin/nft_type',
          }),
        ]);

        const campaignNftTypes = campaignResult.data.list_nft_types;
        const nftTypesShow = nftTypesResult.data
          .filter((item) => campaignNftTypes.indexOf(item.id) !== -1)
          .map((item) => ({
            ...item,
            label: item.label,
            value: item.id,
          }));

        setNftTypes(nftTypesShow);

        setCampaign(campaignResult.data);
      } catch (e) {
        console.log(e);
      }
    };

    if (match.params.id) {
      getCampaignDetail(match.params.id);
    }
  }, []);

  const handleMinting = async (values) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('description', values.description);

      if (isVideo(values.file?.file?.name)) {
        formData.append('video', values.file.src);
        formData.append('image', values.thumb_image.src);
      } else {
        formData.append('image', values.file.src);
      }
      formData.append('lootbox_campaign_id', match.params.id);
      formData.append('total_supply', values.total_supply);
      formData.append('nft_type_id', values.nft_type_id?.value);

      await Api.post({
        url: '/admin/nft/minting',
        data: formData,
      });

      setSelectedMintingItem(values);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  const createNewItem = () => {
    reset();
    setSelectedNftType(null);
    setSelectedMintingItem(null);
  };

  const { formState } = useSelector(mapStateToProps);

  // console.log(formState.file);
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div
          className={classNames(classes.row, classes.rowCenter, classes.mb40)}
        >
          <a
            className={classes.btnBack}
            onClick={() => {
              history.goBack();
            }}
          >
            <img src={arrowLeft} className={classes.arrowLeft} alt='arrow' />
          </a>
          <h2 className={classes.title}>{campaign?.title}</h2>
        </div>

        <div className={classNames(classes.row, classes.rowContent)}>
          <form className={classes.left} onSubmit={handleSubmit(handleMinting)}>
            <h4 className={classes.subTitle}>Upload file</h4>
            <Field
              name='file'
              component={DropzonUploaderField}
              maxFiles={1}
              // accept='image/*'
              // imageOnly
            />

            {formState.file?.file?.name &&
              isVideo(formState.file.file.name) && (
                <Field name='thumb_image' component={ThumbImageField} />
              )}

            <h4 className={classNames(classes.subTitle, classes.mt20)}>
              Information
            </h4>
            <Field
              name='name'
              component={InputField}
              label='Name'
              h50
              maxLength={50}
            />
            <Field
              name='description'
              component={TextAreaField}
              label='Description'
              rows={10}
              maxLength={500}
            />

            <div className={classNames(classes.row, classes.mb25)}>
              <div className={classes.col}>
                <p className={classes.label}>Rarity</p>
              </div>
              <div className={classes.col}>
                <Field
                  name='nft_type_id'
                  component={SelectField}
                  h50
                  options={nftTypes}
                  placeholder='Select rarity'
                  onChange={(val) => {
                    setSelectedNftType(val);
                  }}
                />
              </div>
            </div>

            <div className={classNames(classes.row)}>
              <div className={classes.col}>
                <p className={classes.label}>Number of editions</p>
              </div>
              <div className={classes.col}>
                <Field
                  name='total_supply'
                  component={InputField}
                  h50
                  placeholder='Eg: 5'
                />
              </div>
            </div>
            <Button
              className={classNames('btn btnMain w100 mt40', classes.mt40)}
              type='submit'
              loading={loading}
            >
              Create item
            </Button>
          </form>
          <div className={classes.right}>
            <p className={classes.subTitle}>Preview</p>
            <div className={classes.preview}>
              <div className={classes.rowBetween}>
                {selectedNftType && (
                  <div
                    className={classes.rare}
                    style={{
                      color:
                        COLOR_MAPPING[selectedNftType.collor] ||
                        selectedNftType.collor,
                      borderColor:
                        COLOR_MAPPING[selectedNftType.collor] ||
                        selectedNftType.collor,
                    }}
                  >
                    {selectedNftType.label}
                  </div>
                )}

                <div className={classes.numberEditions}>
                  {formState.total_supply} editions
                </div>
              </div>
              <div className={classes.imageWrapper}>
                {formState.file?.file?.name && (
                  <>
                    {isVideo(formState.file?.file?.name) ? (
                      <>
                        {formState.thumb_image?.file?.name && (
                          <img
                            src={formState.thumb_image?.src}
                            className={classes.image}
                            alt='preview'
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={formState.file?.src}
                        className={classes.image}
                        alt='preview'
                      />
                    )}
                  </>
                )}
              </div>
              <div className={classes.cardContent}>
                <p className={classes.name}>{formState.name}</p>
                <div className={classes.row}>
                  <img
                    src={
                      campaign?.artist_avatar
                        ? `${campaign.artist_avatar}`
                        : defaultAvatar
                    }
                    alt='avatar'
                    className={classes.avatar}
                  />
                  <p className={classes.owner}>{campaign?.artist_name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <MintingItemSuccess
        show={!!selectedMintingItem}
        handleClose={() => setSelectedMintingItem(null)}
        item={selectedMintingItem}
        campaignId={match.params?.id}
        createNewItem={createNewItem}
      />
    </div>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.file || !values.file?.src) {
    errors.file = 'Please upload a image';
  } else if (
    isVideo(values.file.file.name) &&
    (!values.thumb_image || !values.thumb_image?.src)
  ) {
    errors.thumb_image = 'Please upload a thumb image';
  }

  if (!values.name) {
    errors.name = 'Please enter name';
  } else if (values.name.length < 5) {
    errors.name = 'Please enter name min 5 characters';
  }

  if (!values.description) {
    errors.description = 'Please enter description';
  } else if (values.description.length < 5) {
    errors.description = 'Please enter description min 5 characters';
  }

  if (!values.total_supply) {
    errors.total_supply = 'Please enter number of editions';
  }

  if (!values.nft_type_id) {
    errors.nft_type_id = 'Please select rarity';
  }

  return errors;
};

export default reduxForm({
  form: 'MintingItem',
  validate,
})(MintingItem);
