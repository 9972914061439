import React from 'react';
import { roundNumber } from 'utils/utils';
import { COLOR_MAPPING } from 'utils/constants';
import classes from './SerialContents.module.scss';

const SerialContents = ({ serialContents }) => (
  <div className={classes.container}>
    <table className={classes.table}>
      <thead className={classes.thead}>
        <tr>
          <th className={classes.left}>Rarity</th>
          <th>Amount</th>
          <th>Total Minted</th>
          <th>Probability</th>
        </tr>
      </thead>
      <tbody>
        {serialContents.serialContentDatas &&
          serialContents.serialContentDatas.map((item, i) => (
            <tr key={item.nft_type?.id || i} className={classes.tbRow}>
              <td className={classes.left}>
                <div
                  className={classes.nftType}
                  style={{
                    borderColor:
                      COLOR_MAPPING[item.nft_type?.collor] ||
                      item.nft_type?.collor,
                    color:
                      COLOR_MAPPING[item.nft_type?.collor] ||
                      item.nft_type?.collor,
                  }}
                >
                  {item.nft_type?.label}
                </div>
              </td>
              <td>{item.amount}</td>
              <td>{item.total_supply}</td>
              <td>{roundNumber(+item.probability * 100)}%</td>
            </tr>
          ))}
        <tr className={classes.total}>
          <td className={classes.left}>Total</td>
          <td>{serialContents.total_amount}</td>
          <td>{serialContents.total_supply}</td>
          <td>100%</td>
        </tr>
      </tbody>
    </table>
  </div>
);
export default SerialContents;
