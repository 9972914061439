import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import closeIcon from 'images/close.svg';
import Button from 'components/Button';
import { formatStringToNumber } from 'utils/utils';
import burnIcon from 'images/burn.svg';
import * as Api from 'api/api';
import { useDispatch } from 'react-redux';
import {
  showNotification,
  handleShowConfirm,
} from 'layout/CommonLayout/actions';
import { COLOR_MAPPING } from 'utils/constants';
import classes from './ItemDetail.module.scss';

const ItemDetail = ({
  show,
  handleClose,
  item = {},
  campaign,
  getCampaignDetail,
  disabled,
}) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const burnItem = async () => {
    dispatch(
      handleShowConfirm({
        title: 'Confirm',
        description:
          'Burning an NFT destroys the NFT and removes it from your profile. Please note this action cannot be reversed.',
        handleOk: async () => {
          try {
            setLoading(true);
            await Api.post({
              url: `/admin/nft/burn/${item.id}`,
            });
            getCampaignDetail(campaign.id);
            setLoading(false);
            handleClose();
            dispatch(
              showNotification({
                type: 'SUCCESS',
                message: 'Burn success!',
              }),
            );
          } catch (e) {
            setLoading(false);
          }
        },
      }),
    );
  };

  return (
    <Modal show={show} onHide={handleClose} size='xl'>
      <div className={classes.container}>
        <div className={classes.head}>
          <h2 className={classes.title}>Item detail</h2>
          <a className={classes.btnClose} onClick={handleClose}>
            <img
              src={closeIcon}
              className={classes.closeIcon}
              alt='closeIcon'
            />
          </a>
        </div>
        <div className={classes.content}>
          <div className={classes.row}>
            <div className={classes.imageWrapper}>
              {item?.video ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video width='490' controls>
                  <source src={item.video} />
                </video>
              ) : (
                <img src={item?.image} className={classes.img} alt='item' />
              )}
            </div>
            <div className={classes.info}>
              <p className={classes.itemName}>{item?.name}</p>
              <p className={classes.text}>
                Campaign:{' '}
                <b className={classes.campaignName}>{campaign?.title}</b>
              </p>
              <p className={classes.text}>
                Artist: <b>{campaign?.artist_name}</b>
              </p>
              <p className={classes.text}>
                Rarity:{' '}
                <div
                  className={classes.nftType}
                  style={{
                    borderColor:
                      COLOR_MAPPING[item?.nft_type?.collor] ||
                      item?.nft_type?.collor,
                    color:
                      COLOR_MAPPING[item?.nft_type?.collor] ||
                      item?.nft_type?.collor,
                  }}
                >
                  {item?.nft_type?.label}
                </div>
              </p>
              <p className={classes.text}>
                Amount: <b>{formatStringToNumber(item?.total_supply)}</b>
              </p>
              <p className={classes.label}>Description</p>
              <p className={classes.descriptionText}>{item?.description}</p>
              <div className={classes.actions}>
                <Button
                  className={classes.btnBurn}
                  loading={loading}
                  onClick={burnItem}
                  disabled={disabled}
                >
                  <img src={burnIcon} className={classes.burnIcon} alt='burn' />
                  Burn NFT
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ItemDetail;
