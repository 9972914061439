import React, { useEffect, useState } from 'react';
import arrowLeft from 'images/arrow-left.svg';
import * as Api from 'api/api';
import history from 'utils/history';
import classes from './CampaignItems.module.scss';
import Items from './Items';

const CampaignItems = ({ match }) => {
  const [campaign, setCampaign] = useState({});

  const getCampaignDetail = async (id) => {
    try {
      const result = await Api.get({
        url: `/admin/campaign/${id}`,
      });

      setCampaign(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (match.params.id) {
      getCampaignDetail(match.params.id);
    }
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <a
          className={classes.btnBack}
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={arrowLeft} className={classes.arrowLeft} alt='arrow' />
        </a>
        <h2 className={classes.campaignName}>{campaign?.title}</h2>
      </div>

      <div className={classes.rowBetween}>
        <p className={classes.subtitle}>Drop items</p>
      </div>
      {campaign.nfts && campaign.nfts.length > 0 ? (
        <Items
          items={campaign.nfts || []}
          campaign={campaign}
          getCampaignDetail={getCampaignDetail}
        />
      ) : (
        <div className={classes.empty}>
          <h3 className={classes.title}>Empty</h3>
          <p className={classes.description}>Don&apos;t have any drop items</p>
        </div>
      )}

      <div className={classes.actions}>
        <a
          className='btn btnMain'
          onClick={() => {
            history.push(`/admin/minting-item/${match.params.id}`);
          }}
        >
          Create new item
        </a>
      </div>
    </div>
  );
};

export default CampaignItems;
