import React, { useState, useRef } from 'react';
import closeIcon from 'images/close.svg';
import imageAddIcon from 'images/image-add.svg';
import { useDispatch } from 'react-redux';
import { showNotification } from 'layout/CommonLayout/actions';
import * as Api from 'api/api';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { renderField } from 'Form';
import classes from './ThumbImageField.module.scss';

const ThumbImageField = ({ input }) => {
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleUploadThumbImage = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    if (file.size > 5 * 1024 * 1024) {
      dispatch(
        showNotification({
          type: 'ERROR',
          message: 'File is so large > 5MB',
        }),
      );
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      inputRef.current.value = '';
      const { onChange } = input;
      onChange({ file, presrc: reader.result });
      handleUpload(file);
    };
  };

  const handleUpload = async (file) => {
    try {
      setLoading(true);

      const result = await Api.get({
        url: '/media/presign-upload',
      });

      const extend = file.name ? file.name.split('.').pop() : 'File';

      // const formData = new FormData();
      // formData.append('files', file);
      await axios.put(result.data.url, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': extend === 'svg' && 'image/svg+xml',
        },
      });

      const [imageUrl] = result.data.url.split('?');

      // console.log('response upload image', result.data.url)
      input.onChange({ file, src: imageUrl });
      setLoading(false);
    } catch (e) {
      input.onChange(null);
      setLoading(false);
    }

    // const newValue = value || []
  };

  return (
    <div className={classes.thumbImage}>
      <h2 className={classes.title}>Preview image</h2>
      <p className={classes.description}>
        Because you&apos;ve uploaded a video, please provide a thumbnail image
        of your item. Maximum image size is 5MB.
      </p>
      <div className={classes.wrapper}>
        {input.value.src && (
          <a
            className={classes.btnRemoveImage}
            onClick={() => {
              input.onChange(null);
            }}
          >
            <img src={closeIcon} className={classes.closeIcon} alt='close' />
          </a>
        )}

        <label className={classes.wrapper}>
          {input.value.src || input.value.presrc ? (
            <div className={classes.imageWrapper}>
              <img
                src={input.value.src || input.value.presrc}
                className={classes.image}
                alt='prev'
              />
              {loading && (
                <div className={classes.loader}>
                  <Loader type='Oval' color='#DEB856' height={24} width={24} />
                </div>
              )}
            </div>
          ) : (
            <div className={classes.uploadThumbImage}>
              <img
                src={imageAddIcon}
                className={classes.imageAddIcon}
                alt='add'
              />
              <p className={classes.upload}>Upload image</p>
            </div>
          )}

          <input
            style={{ display: 'none' }}
            type='file'
            accept={'image/*'}
            multiple
            onChange={handleUploadThumbImage}
            ref={inputRef}
          />
        </label>
      </div>
    </div>
  );
};

export default renderField(ThumbImageField);
