import React, { useEffect, useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import InputField from 'components/InputField';
import Button from 'components/Button';
import classNames from 'classnames';
import * as Api from 'api/api';
import { useDispatch } from 'react-redux';
import { showNotification } from 'layout/CommonLayout/actions';
import classes from './Configuration.module.scss';

const Configuration = ({ handleSubmit, initialize }) => {
  const [loading, setLoading] = useState(false);

  const [fees, setFees] = useState([]);

  const dispatch = useDispatch();

  const getConfiguration = async () => {
    try {
      const result = await Api.get({
        url: '/v1/nft/fees',
      });
      console.log('result', result);
      const feeObj = {};
      result?.data?.fees.forEach((item) => {
        feeObj[item.name] = item.value;
      });

      initialize(feeObj);

      setFees(result?.data?.fees);
    } catch (e) {
      return Promise.reject();
    }
  };

  useEffect(() => {
    getConfiguration();
  }, []);

  const handleSubmitConfig = async (values) => {
    try {
      setLoading(true);

      const data = fees.map((item) => ({
        ...item,
        value: +values[item.name],
      }));

      await Api.put({
        url: '/v1/nft/fees',
        data,
      });

      dispatch(
        showNotification({
          type: 'SUCCESS',
          message: 'Update configuration fee success',
        }),
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleSubmitConfig)}
    >
      <h2 className={classes.title}>Fee structure</h2>
      <div className={classes.group}>
        <div className={classes.head}>
          <h3 className={classes.headTitle}>Universal configurations</h3>
        </div>
        <div className={classes.groupBody}>
          <div className={classes.row}>
            <div className={classes.box}>
              <div className={classes.boxHead}>Primary sale</div>
              <div className={classNames(classes.row, classes.mb20)}>
                <p className={classes.label}>Commission fee (%)</p>
                <div className={classes.field}>
                  <Field
                    name='PRIMARY_SALE_FEE'
                    component={InputField}
                    inputType='number'
                  />
                </div>
              </div>
              {/* <div className={classNames(classes.row, classes.mb20)}>
                <p className={classes.label}>Spores Secondary royalty (%)</p>
                <div className={classes.field}>
                  <Field
                    name='primarySaleRoyalty'
                    component={InputField}
                    inputType='number'
                  />
                </div>
              </div> */}
            </div>
            <div className={classes.box}>
              <div className={classes.boxHead}>Secondary sale</div>
              <div className={classNames(classes.row, classes.mb20)}>
                <p className={classes.label}>Commission fee (%)</p>
                <div className={classes.field}>
                  <Field
                    name='SECONDARY_SALE_FEE'
                    component={InputField}
                    inputType='number'
                  />
                </div>
              </div>
              <div className={classNames(classes.row, classes.mb20)}>
                <p className={classes.label}>Secondary royalty (%)</p>
                <div className={classes.field}>
                  <Field
                    name='ROYALTY_FEE'
                    component={InputField}
                    inputType='number'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={classes.group}>
        <div className={classes.head}>
          <h3 className={classes.headTitle}>
            Applicable range of fee for specific User (%):
          </h3>
          <h3 className={classes.headDescription}>
            Set up the range of fee that can be applied to a specific User.
          </h3>
        </div>
        <div className={classes.groupBody}>
          <div className={classes.row}>
            <div className={classes.box}>
              <div className={classes.boxHead}>Primary sale</div>
              <div className={classNames(classes.row, classes.mb20)}>
                <p className={classes.label}>Spores Commission fee (%)</p>
                <div className={classes.field}>
                  <div className={classes.col}>
                    <Field
                      name='primarySaleCommissionMin'
                      component={InputField}
                      inputType='number'
                      placeholder='Min'
                    />
                  </div>
                  <div className={classes.divider} />
                  <div className={classes.col}>
                    <Field
                      name='primarySaleCommissionMax'
                      component={InputField}
                      inputType='number'
                      placeholder='Max'
                    />
                  </div>
                </div>
              </div>
              <div className={classNames(classes.row, classes.mb20)}>
                <p className={classes.label}>Spores Secondary royalty (%)</p>
                <div className={classes.field}>
                  <div className={classes.col}>
                    <Field
                      name='primarySaleRoyaltyMin'
                      component={InputField}
                      inputType='number'
                      placeholder='Min'
                    />
                  </div>
                  <div className={classes.divider} />
                  <div className={classes.col}>
                    <Field
                      name='primarySaleRoyaltyMax'
                      component={InputField}
                      inputType='number'
                      placeholder='Max'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.box}>
              <div className={classes.boxHead}>Secondary sale</div>
              <div className={classNames(classes.row, classes.mb20)}>
                <p className={classes.label}>Spores Commission fee (%)</p>
                <div className={classes.field}>
                  <div className={classes.col}>
                    <Field
                      name='secondarySaleCommissionFeeMin'
                      component={InputField}
                      inputType='number'
                      placeholder='Min'
                    />
                  </div>
                  <div className={classes.divider} />
                  <div className={classes.col}>
                    <Field
                      name='secondarySaleCommissionFeeMax'
                      component={InputField}
                      inputType='number'
                      placeholder='Max'
                    />
                  </div>
                </div>
              </div>
              <div className={classNames(classes.row, classes.mb20)}>
                <p className={classes.label}>Spores Secondary royalty (%)</p>
                <div className={classes.field}>
                  <div className={classes.col}>
                    <Field
                      name='secondarySaleRoyaltyFeeMin'
                      component={InputField}
                      inputType='number'
                      placeholder='Min'
                    />
                  </div>
                  <div className={classes.divider} />
                  <div className={classes.col}>
                    <Field
                      name='secondarySaleRoyaltyFeeMax'
                      component={InputField}
                      inputType='number'
                      placeholder='Max'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
      </div> */}
      <div className={classes.bottom}>
        <p className={classes.note}>NOTE:</p>
        <p className={classes.text}>
          When Admin sets up a specific fee structure to user, it shall
          overwrite the Universal Configurations. The fee structure applied to a
          specific user must comply with the range predefined above.
        </p>
        <Button className='btn btnMain' type='submit' loading={loading}>
          Save
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'ConfigurationForm',
})(Configuration);
