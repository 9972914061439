import React, { useState } from 'react';
import classNames from 'classnames';
import classes from './Items.module.scss';
import Item from '../Item';
import ItemDetail from '../ItemDetail';

const Items = ({ items, campaign, getCampaignDetail, disabled }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  return (
    <div className={classNames(classes.items, 'row')}>
      {items &&
        items.map((item) => (
          <div className='col-xs-12 col-md-4 col-lg-3' key={item.id}>
            <Item
              item={item}
              handleItemClick={(val) => {
                setSelectedItem(val);
              }}
            />
          </div>
        ))}

      <ItemDetail
        show={!!selectedItem}
        handleClose={() => setSelectedItem(null)}
        item={selectedItem}
        campaign={campaign}
        getCampaignDetail={getCampaignDetail}
        disabled={disabled}
      />
    </div>
  );
};

export default Items;
