import React, { useEffect, useState } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import InputField from 'components/InputField';
import DatePickerField from 'components/DatePickerField';
import Button from 'components/Button';
import classNames from 'classnames';
import arrowLeft from 'images/arrow-left.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleShowConfirm,
  showNotification,
} from 'layout/CommonLayout/actions';
import moment from 'moment';
import history from 'utils/history';
import { createStructuredSelector } from 'reselect';
import * as Api from '../../../api/api';
import classes from './WavesForm.module.scss';

const mapStateToProps = createStructuredSelector({
  formState: (state) => getFormValues('WavesForm')(state) || {},
});

const WavesForm = ({ initialize, match, invalid, touch, change }) => {
  const [campaign, setCampaign] = useState({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState('LIVE');

  const { formState } = useSelector(mapStateToProps);

  const getCampaignDetail = async (id) => {
    try {
      const [result, campaignRemain] = await Promise.all([
        Api.get({
          url: `/admin/campaign/${id}`,
        }),
        Api.get({
          url: `/admin/campaign_remain/${id}`,
        }),
      ]);

      let newCampaignStatus = 'DRAFT';

      result.data?.campaign_waves.forEach((wave) => {
        if (wave.is_public) {
          const endDate = moment(wave.end_time).format('YYYYMMDDHHmmss');
          const currentDate = moment().format('YYYYMMDDHHmmss');
          if (currentDate < endDate) {
            newCampaignStatus = 'LIVE';
          } else if (newCampaignStatus === 'DRAFT') {
            newCampaignStatus = 'FINISHED';
          }
        }
      });

      setCampaignStatus(newCampaignStatus);
      setTimeout(() => {
        change(
          'total_supply',
          campaignRemain.data.total_supply -
            campaignRemain.data.sold_box_quantity,
        );
      }, 1000);

      setCampaign(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  const dispatch = useDispatch();

  useEffect(async () => {
    if (match.params.waveId) {
      const [result] = await Promise.all([
        Api.get({
          url: `/admin/campaign_wave/${match.params.waveId}`,
        }),
      ]);
      initialize({
        wave_name: result.data.wave_name || '',
        wave_slug: result.data.wave_slug || '',
        start_time:
          !result.data.start_time ||
          result.data.start_time === '0001-01-01T00:00:00Z'
            ? ''
            : moment(result.data.start_time).format('MM/DD/YYYY HH:mm'),
        max_box_quantity: result.data.max_box_qantity,
        allocation_per_user: result.data.allocation_per_user,
        first_max_quantity: result.data.first_max_quantity,
        second_max_quantity: result.data.second_max_quantity,
        first_price: result.data.first_price,
        second_price: result.data.second_price,
        end_time:
          !result.data.end_time ||
          result.data.end_time === '0001-01-01T00:00:00Z'
            ? ''
            : moment(result.data.end_time).format('MM/DD/YYYY HH:mm'),
      });
    }
  }, []);

  useEffect(() => {
    if (match.params.campaignId) {
      getCampaignDetail(match.params.campaignId);
    }
  }, []);

  const handleSaveWave = async (hideLoading) => {
    if (invalid) {
      touch(
        'start_time',
        'end_time',
        'max_box_quantity',
        'allocation_per_user',
        'first_max_quantity',
        'second_max_quantity',
        'first_price',
        'second_price',
      );
      return;
    }
    try {
      setLoadingSave(!hideLoading);
      const values = formState;
      let url = '';
      if (match.params.waveId) {
        url = `/admin/campaign_wave/${match.params.waveId}`;
      } else {
        url = '/admin/campaign_wave';
      }
      const data = {
        ...values,
        lootbox_campaign_id: +match.params.campaignId,
        start_time: moment.utc(moment(values.start_time)).format(),
        end_time: moment.utc(moment(values.end_time)).format(),
        max_box_quantity: +values.max_box_quantity,
        allocation_per_user: +values.allocation_per_user,
        first_max_quantity: +values.first_max_quantity,
        second_max_quantity: +values.second_max_quantity,
      };

      let waveData;

      if (match.params.waveId) {
        waveData = await Api.put({
          url,
          data,
        });
      } else {
        waveData = await Api.post({
          url,
          data,
        });
      }
      if (!hideLoading) {
        dispatch(
          showNotification({
            type: 'SUCCESS',
            message: match.params.waveId
              ? 'Update wave success!'
              : 'Create wave success!',
          }),
        );

        history.push('/admin/campaign');
      }
      setLoadingSave(false);
      return waveData.data;
    } catch (e) {
      setLoadingSave(false);
      return Promise.reject(e);
    }
  };

  const validateCampaign = () => {
    if (
      !campaign.banner_image ||
      !campaign.box_image ||
      !campaign.title ||
      !campaign.open_end_time ||
      !campaign.second_royality_fee ||
      !campaign.artist_name ||
      !campaign.total_supply ||
      !campaign.artist_avatar ||
      !campaign.artist_description
    ) {
      return false;
    }

    return true;
  };

  const handlePublish = () => {
    if (campaignStatus === 'LIVE') {
      return;
    }
    if (!validateCampaign()) {
      dispatch(
        showNotification({
          type: 'ERROR',
          message: 'Please update campaign detail before public wave',
        }),
      );
      return;
    }
    if (invalid) {
      touch(
        'start_time',
        'end_time',
        'max_box_quantity',
        'allocation_per_user',
        'first_max_quantity',
        'second_max_quantity',
        'first_price',
        'second_price',
      );
      return;
    }
    dispatch(
      handleShowConfirm({
        title: 'Publish',
        description: 'Do you want to publish this wave ?',
        handleOk: async () => {
          try {
            setLoadingPublish(true);
            const waveData = await handleSaveWave(true);
            const result = await Api.put({
              url: `/admin/campaign_wave_public/${waveData.id}`,
            });
            setLoadingPublish(false);
            if (result.code > 300) {
              change('total_supply', result.remain_box_total);
              // dispatch(
              //   showNotification({
              //     type: 'ERROR',
              //     message: result.message,
              //   }),
              // );
            } else {
              dispatch(
                showNotification({
                  type: 'SUCCESS',
                  message: 'Public wave success!',
                }),
              );
              history.push('/admin/campaign');
            }
          } catch (e) {
            console.log(e);
            setLoadingPublish(false);
            const remainBoxTotal = e.data.remain_box_total;
            if (remainBoxTotal && remainBoxTotal > 0) {
              change('total_supply', remainBoxTotal);
              change('max_box_quantity', remainBoxTotal);
              if (formState.allocation_per_user > remainBoxTotal) {
                change('allocation_per_user', remainBoxTotal);
              }

              if (formState.first_max_quantity > remainBoxTotal) {
                change('first_max_quantity', remainBoxTotal);
              }

              const firstMax =
                formState.first_max_quantity > remainBoxTotal
                  ? remainBoxTotal
                  : formState.first_max_quantity;
              change('second_max_quantity', remainBoxTotal - firstMax);
            }
          }
        },
      }),
    );
  };

  return (
    <div className={classes.container}>
      <div className={classNames(classes.row, classes.mb27)}>
        <a
          className={classes.btnBack}
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={arrowLeft} className={classes.arrowLeft} alt='arrow' />
        </a>
        <h2 className={classes.campaignName}>{campaign.title}</h2>
      </div>
      <Field
        name='wave_name'
        component={InputField}
        label='Wave name'
        placeholder='Wave name'
        maxLength={15}
      />
      <Field
        name='wave_slug'
        component={InputField}
        label='Wave slug'
        placeholder='Wave slug'
      />
      <div className={classes.row}>
        <div className={classes.col}>
          <Field
            name='start_time'
            component={DatePickerField}
            label='Start date'
            viewMode='days'
            timeFormat='HH:mm'
            minDate={moment().add(-1, 'days').toDate()}
            maxDate={formState.end_time}
            required
          />
        </div>
        <div className={classes.col}>
          <Field
            name='end_time'
            component={DatePickerField}
            label='End date'
            viewMode='days'
            timeFormat='HH:mm'
            minDate={
              formState.start_time
                ? moment(formState.start_time).add(-1, 'days').toDate()
                : moment().add(-1, 'days').toDate()
            }
            required
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>
          <Field
            name='max_box_quantity'
            component={InputField}
            label={`Total number of boxes (max: ${
              formState.total_supply || 0
            })`}
            inputType='number'
            placeholder='E.g. 100'
            required
            onChange={(val) => {
              change(
                'second_max_quantity',
                `${
                  (val ? +val : 0) -
                  (formState.first_max_quantity
                    ? +formState.first_max_quantity
                    : 0)
                }`,
              );
            }}
          />
        </div>
        <div className={classes.col}>
          <Field
            name='allocation_per_user'
            component={InputField}
            label={`Allocation per User (max: ${
              formState.max_box_quantity || 0
            })`}
            inputType='number'
            placeholder='E.g. 100'
            required
          />
        </div>
      </div>

      <h3 className={classes.subTitle}>Box allocation by currency</h3>
      <div className={classes.row}>
        <div className={classes.col}>
          <Field
            name='first_max_quantity'
            component={InputField}
            label={`Number of boxes in SPO (max: ${
              formState.total_supply || 0
            })`}
            inputType='number'
            placeholder='E.g. 100'
            required
            onChange={(val) => {
              change(
                'second_max_quantity',
                `${
                  (formState.max_box_quantity
                    ? +formState.max_box_quantity
                    : 0) - (val ? +val : 0)
                }`,
              );
            }}
          />
        </div>
        <div className={classes.col}>
          <Field
            name='first_price'
            component={InputField}
            label='Price per box in SPO'
            inputType='number'
            placeholder='E.g. 100'
            required
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>
          <Field
            name='second_max_quantity'
            component={InputField}
            label='Number of boxes in USDT/USDC/BUSD'
            inputType='number'
            placeholder='E.g. 100'
            disabled
          />
        </div>
        <div className={classes.col}>
          <Field
            name='second_price'
            component={InputField}
            label='Price per box in USDT/USDC/BUSD'
            inputType='number'
            placeholder='E.g. 100'
            required
          />
        </div>
      </div>
      <div className={classNames(classes.row, classes.mt54)}>
        <div className={classes.col}>
          <Button
            className='btn btnSecond btnLarge w100'
            onClick={() => handleSaveWave()}
            loading={loadingSave}
          >
            Save
          </Button>
        </div>
        <div className={classes.col} onClick={handlePublish}>
          <Button
            className='btn btnMain btnLarge w100'
            onClick={handlePublish}
            type='button'
            loading={loadingPublish}
            disabled={campaignStatus === 'LIVE'}
          >
            Publish
          </Button>
        </div>
      </div>
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.start_time) {
    errors.start_time = 'Please select start time';
  }
  if (!values.end_time) {
    errors.end_time = 'Please select end time';
  }
  if (values.start_time && values.end_time) {
    const startTime = moment(values.start_time).format('YYYYMMDDHHmm');
    const endTime = moment(values.end_time).format('YYYYMMDDHHmm');
    const currentDate = moment().format('YYYYMMDDHHmm');
    if (endTime < startTime) {
      errors.end_time = 'Please select end time > start time';
    }

    if (startTime < currentDate) {
      errors.start_time = 'Please select start time in further';
    }

    if (endTime < currentDate) {
      errors.end_time = 'Please select end time in further';
    }
  }

  if (!values.max_box_quantity) {
    errors.max_box_quantity = 'Please enter total number of boxes';
  } else if (+values.max_box_quantity > +values.total_supply) {
    errors.max_box_quantity = `Please enter total number of box <= ${values.total_supply}`;
  }

  if (!values.allocation_per_user && `${values.allocation_per_user}` !== '0') {
    errors.allocation_per_user = 'Please enter allocation per user';
  } else if (+values.allocation_per_user > +values.max_box_quantity) {
    errors.allocation_per_user = `Please enter allocation per user <= ${values.max_box_quantity}`;
  }
  if (!values.first_max_quantity && `${values.first_max_quantity}` !== '0') {
    errors.first_max_quantity = 'Please enter number of box in SPO';
  } else if (+values.first_max_quantity > +values.max_box_quantity) {
    errors.first_max_quantity = `Please enter number of box in SPO <= ${values.max_box_quantity}`;
  }
  if (!values.first_price) {
    errors.first_price = 'Please enter price per box in SPO';
  }

  if (!values.second_price) {
    errors.second_price = 'Please enter price per box in USDT/USDC/BUSD';
  }

  if (!values.wave_slug) {
    errors.wave_slug = 'Please enter wave slug';
  }
  return errors;
};

export default reduxForm({
  form: 'WavesForm',
  validate,
})(WavesForm);
