import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Switch, useRouteMatch } from 'react-router-dom';
import AdminPrivateRoute from 'routes/AdminPrivateRoute';
import Users from 'pages/Admin/Users';
import SaleSummary from 'pages/Admin/SaleSummary';
import ChangePassword from 'pages/Admin/ChangePassword';
import CreateCollection from 'pages/Admin/CreateCollection';
import Profile from 'pages/Admin/Profile';
import CampaignManagement from 'pages/Admin/CampaignManagement';
import Configuration from 'pages/Admin/Configuration';
import CampaignItems from 'pages/Admin/CampaignItems';
import CampaignDetail from 'pages/Admin/CampaignDetail';
import { compose } from 'redux';
import { injectSaga } from 'utils/injectSaga';
import MintingItem from 'pages/Admin/MintingItem';
import WavesForm from 'pages/Admin/WavesForm';
import Header from '../../components/Header';
// import SideBar from '../../components/SideBar';
import classes from './AdminLayout.module.scss';
import saga from './saga';

const AdminLayout = () => {
  // console.log(props)
  const { path } = useRouteMatch();
  /**
   * state quản lý việc show menu hay không
   */
  const [showMenu, setShowMenu] = useState('FULL');
  const [noTransition, setNoTransition] = useState(true);
  const [showMenuClass, setShowMenuClass] = useState(false);

  useEffect(() => {
    setShowMenu(localStorage.getItem('showMenu') || 'FULL');
  }, []);

  /**
   * xử lý toggle menu
   */
  const handleToggleMenu = () => {
    let newShowMenu = null;
    if (showMenu === 'FULL' || !showMenu) {
      newShowMenu = 'COLLAPSE';
    } else if (showMenu === 'COLLAPSE') {
      newShowMenu = 'FULL';
    }
    localStorage.setItem('showMenu', newShowMenu);
    setShowMenu(newShowMenu);
    setNoTransition(false);
  };

  /**
   * xử lý show menu
   */
  const handleShowMenuMobile = () => {
    setShowMenuClass(true);
  };

  /**
   * xử lý hide menu
   */
  const handleHideMenuMobile = () => {
    setShowMenu(false);
    setTimeout(() => {
      setShowMenuClass(false);
    }, 400);
  };

  return (
    <div
      className={classNames(classes.container, 'adminContainer')}
      id='outer-container'
    >
      {showMenuClass && (
        <div
          className={classNames('fade modal-backdrop show', classes.backdrop)}
        />
      )}
      {/* <div
        className={classNames(
          classes.sideBar,
          showMenu === 'COLLAPSE' && classes.sideBarCollapseWrapper,
          showMenuClass && classes.showSideBar,
        )}
      >
        <SideBar
          screen={screen}
          showMenu={!!showMenu}
          collapse={showMenu === 'COLLAPSE'}
          handleToggleMenu={handleToggleMenu}
          pathname={location.pathname}
        />
      </div> */}
      <div
        className={classNames(
          classes.header,
          showMenu === 'COLLAPSE' && classes.headerCollapse,
        )}
      >
        <Header
          showMenu
          handleShowMenuMobile={handleShowMenuMobile}
          handleHideMenu={handleHideMenuMobile}
          handleToggleMenu={handleToggleMenu}
          collapse={showMenu === 'COLLAPSE'}
          showMenuClass={showMenuClass}
        />
      </div>
      <div className={classes.content}>
        <div
          className={classNames(
            classes.main,
            showMenu === 'FULL' && classes.showMenu,
            showMenu === 'COLLAPSE' && classes.showMenuCollapse,
            noTransition && classes.noTransition,
          )}
          id='page-wrap'
        >
          <Switch>
            <AdminPrivateRoute path={`${path}/users`} component={Users} exact />
            <AdminPrivateRoute
              path={`${path}/sale-summary`}
              component={SaleSummary}
            />
            <AdminPrivateRoute
              path={`${path}/change-password`}
              component={ChangePassword}
            />
            <AdminPrivateRoute
              path={`${path}/create-collection`}
              component={CreateCollection}
            />
            <AdminPrivateRoute path={`${path}/profile`} component={Profile} />
            <AdminPrivateRoute
              path={`${path}/campaign`}
              component={CampaignManagement}
            />
            <AdminPrivateRoute
              path={`${path}/configuration`}
              component={Configuration}
            />
            <AdminPrivateRoute
              path={`${path}/minting-item/:id`}
              component={MintingItem}
            />
            <AdminPrivateRoute
              path={`${path}/campaign-items/:id`}
              component={CampaignItems}
            />
            <AdminPrivateRoute
              path={`${path}/campaign-detail/:id`}
              component={CampaignDetail}
            />
            <AdminPrivateRoute
              path={`${path}/waves-form/:campaignId/:waveId`}
              component={WavesForm}
            />
            <AdminPrivateRoute
              path={`${path}/waves-form/:campaignId`}
              component={WavesForm}
            />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const withSaga = injectSaga({ key: 'admin', saga });

const ComposeAdminLayout = compose(withSaga)(AdminLayout);

export default ComposeAdminLayout;
