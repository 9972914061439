import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import InputField from 'components/InputField';
import Button from 'components/Button';
// import * as Api from 'api/api';
import closeIcon from 'images/close.svg';
import TextAreaField from 'components/TextAreaField';
import classes from './CreateCollectionForm.module.scss';

const CreateCollectionForm = ({
  handleSubmit,
  handleClose,
  selectedCollection,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSubmitUser = async (values) => {
    try {
      setLoading(true);

      console.log('values==>', values);
      handleClose();
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleSubmitUser)}
    >
      <div className={classes.head}>
        <h2 className={classes.title}>
          {selectedCollection && selectedCollection.id
            ? 'Update collection'
            : 'Create collection'}
        </h2>
        <a className={classes.btnClose} onClick={handleClose}>
          <img src={closeIcon} className={classes.closeIcon} alt='closeIcon' />
        </a>
      </div>
      <div className={classes.content}>
        <p className={classes.subTitle}>Information</p>
        <Field name='name' label='Name' required component={InputField} />
        <Field name='symbol' label='Symbol' required component={InputField} />
        <Field
          name='totalSupply'
          label='Total Supply'
          required
          component={InputField}
          inputType='number'
        />
        <Field
          name='description'
          label='Description'
          component={TextAreaField}
        />
      </div>
      <div className={classes.actions}>
        <Button
          className='btn btnMain btnLarge'
          loading={loading}
          type='submit'
        >
          {selectedCollection && selectedCollection.id
            ? 'Update collection'
            : 'Create collection'}
        </Button>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Please enter name';
  }

  if (!values.symbol) {
    errors.symbol = 'Please enter symbol';
  }

  if (!values.totalSupply) {
    errors.totalSupply = 'Please total supply';
  }

  return errors;
};

export default reduxForm({
  form: 'CreateCollection',
  validate,
  enableReinitialize: true,
})(CreateCollectionForm);
