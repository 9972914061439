import React, { useState, useEffect } from 'react';
import { Field, reduxForm, getFormValues } from 'redux-form';
import InputField from 'components/InputField';
import TextAreaField from 'components/TextAreaField';
import DatePickerField from 'components/DatePickerField';
import DropzonUploaderField from 'components/DropzonUploaderField';
import classNames from 'classnames';
import * as Api from 'api/api';
import moment from 'moment';
import arrowLeft from 'images/arrow-left.svg';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { showNotification } from 'layout/CommonLayout/actions';
import history from 'utils/history';
import { isVideo } from 'utils/utils';
import { createStructuredSelector } from 'reselect';
import classes from './CampaignDetail.module.scss';
import Items from '../CampaignItems/Items';
import SerialContents from './SerialContents';
import ThumbImageField from '../CreateCollection/ThumbImageField';

const mapStateToProps = createStructuredSelector({
  formState: (state) => getFormValues('CreateCollection')(state) || {},
});

const CampaignDetail = ({ match, initialize, handleSubmit, change }) => {
  const [campaign, setCampaign] = useState({});
  const [loading, setLoading] = useState(false);
  const [campaignStatus, setCampaignStatus] = useState();
  const [serialContents, setSerialContents] = useState({});

  const { formState } = useSelector(mapStateToProps);

  const fetchCampaignDetail = async (id) => {
    try {
      const result = await Api.get({
        url: `/admin/campaign/${id}`,
      });

      let total_supply = 0;
      if (result.data?.nfts.length > 0) {
        result.data?.nfts.forEach((item) => {
          total_supply += item.total_supply;
        });
      }

      change('total_supply', `${total_supply}`);

      setCampaign(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getCampaignDetail = async (id) => {
    try {
      const result = await Api.get({
        url: `/admin/campaign/${id}`,
      });

      setCampaign(result.data);

      let total_supply = 0;

      const serialContentObj = {};

      if (result.data?.nfts.length > 0) {
        result.data?.nfts.forEach((item) => {
          total_supply += item.total_supply;
          const serialContentItem = serialContentObj[item.nft_type_id];
          if (serialContentItem) {
            serialContentObj[item.nft_type_id] = {
              ...serialContentItem,
              total_supply: serialContentItem.total_supply + item.total_supply,
              amount: serialContentItem.amount + 1,
            };
          } else {
            serialContentObj[item.nft_type_id] = {
              total_supply: item.total_supply,
              nft_type: item.nft_type,
              amount: 1,
            };
          }
        });
      }

      let total_amount = 0;

      const serialContentDatas = Object.values(serialContentObj).map((item) => {
        total_amount += item.amount;
        return {
          ...item,
          probability: item.total_supply / total_supply,
        };
      });

      setSerialContents({ total_amount, total_supply, serialContentDatas });

      let newCampaignStatus = 'DRAFT';

      result.data?.campaign_waves.forEach((wave) => {
        if (wave.is_public) {
          const endDate = moment(wave.end_time).format('YYYYMMDDHHmmss');
          const currentDate = moment().format('YYYYMMDDHHmmss');
          if (currentDate < endDate) {
            newCampaignStatus = 'LIVE';
          } else if (newCampaignStatus === 'DRAFT') {
            newCampaignStatus = 'FINISHED';
          }
        }
      });

      setCampaignStatus(newCampaignStatus);

      initialize({
        title: result.data.title,
        slug: result.data.slug,
        content: result.data.content || '',
        total_supply: `${total_supply}`,
        open_end_time:
          result.data.open_end_time &&
          moment(result.data.open_end_time).format('MM/DD/YYYY HH:mm'),
        second_royality_fee: result.data.second_royality_fee || '',
        seriesContents: result.data.seriesContents && {
          src: result.data.seriesContents,
        },
        banner_image: result.data.banner_image && {
          src: result.data.banner_image,
        },
        artist_avatar: result.data.artist_avatar && {
          src: result.data.artist_avatar,
        },
        box_image: result.data.box_image && {
          src: result.data.box_image,
        },

        artist_name: result.data.artist_name || '',
        artist_description: result.data.artist_description,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const dispatch = useDispatch();

  const handleSaveCampaign = async (values) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append('title', values.title);
      formData.append('slug', values.slug);
      formData.append('content', values.content);
      if (values.banner_image?.src) {
        formData.append('banner_image', values.banner_image.src);
      }
      if (values.thumb_image?.src) {
        formData.append('thumb_image', values.thumb_image.src);
      }
      if (values.box_image?.src) {
        formData.append('box_image', values.box_image.src);
      }
      if (values.artist_avatar?.src) {
        formData.append('artist_avatar', values.artist_avatar.src);
      }
      formData.append('total_supply', values.total_supply);
      formData.append(
        'open_end_time',
        moment.utc(moment(values.open_end_time)).format(),
      );
      formData.append('secondary_royality_fee', values.second_royality_fee);
      formData.append('artist_name', values.artist_name);
      formData.append('artist_description', values.artist_description);

      await Api.put({
        url: `/admin/campaign/${match.params.id}`,
        data: formData,
      });

      dispatch(
        showNotification({
          type: 'SUCCESS',
          message: 'Update campaign success!',
        }),
      );

      history.push('/admin/campaign');

      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (match.params.id) {
      getCampaignDetail(match.params.id);
    }
  }, []);
  return (
    <form
      className={classes.container}
      onSubmit={handleSubmit(handleSaveCampaign)}
    >
      <div className={classNames(classes.row, classes.mb36)}>
        <a
          className={classes.btnBack}
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={arrowLeft} className={classes.arrowLeft} alt='arrow' />
        </a>
        <h3 className={classes.campaignName}>{campaign.title}</h3>
      </div>

      <Field
        name='banner_image'
        component={DropzonUploaderField}
        label='Upload cover photo'
        required
        disabled={campaignStatus !== 'DRAFT'}
        imageOnly
      />
      {isVideo(formState.banner_image?.file?.name) && (
        <Field
          name='thumb_image'
          component={ThumbImageField}
          label='Thumb image'
          disabled={campaignStatus !== 'DRAFT'}
          required
        />
      )}

      <div className={classes.row}>
        <div className={classes.left}>
          <Field
            name='title'
            label='Campaign name'
            component={InputField}
            required
            disabled={campaignStatus !== 'DRAFT'}
            maxLength={25}
          />
          <Field
            name='slug'
            label='Slug'
            component={InputField}
            required
            disabled={campaignStatus !== 'DRAFT'}
          />
          <Field
            name='content'
            label='Campaign description'
            component={TextAreaField}
            required
            disabled={campaignStatus !== 'DRAFT'}
            maxLength={500}
          />

          <div className={classes.row}>
            <div className={classes.col}>
              <Field
                name='total_supply'
                label='Total number of box'
                component={InputField}
                inputType='number'
                // required
                disabled
              />
            </div>
            <div className={classes.col}>
              <Field
                name='open_end_time'
                label='Automatically open all box on'
                component={DatePickerField}
                viewMode='days'
                timeFormat='HH:mm'
                minDate={new Date()}
                required
                disabled={campaignStatus !== 'DRAFT'}
              />
            </div>
          </div>

          <div className={classes.row}>
            <div className={classes.col}>
              <Field
                name='second_royality_fee'
                label='Secondary royalty (%)'
                component={InputField}
                inputType='number'
                required
                disabled={campaignStatus !== 'DRAFT'}
              />
            </div>
            <div className={classes.col} />
          </div>

          <h3 className={classes.subTitle}>Series contents</h3>

          <SerialContents serialContents={serialContents} />
        </div>
        <div className={classes.right}>
          <p className={classes.label}>
            Upload Box Image <span className={classes.required}>*</span>
          </p>
          <Field
            name='box_image'
            component={DropzonUploaderField}
            accept='image/*'
            imageOnly
            containerStyle={classes.mb25}
            disabled={campaignStatus !== 'DRAFT'}
          />
        </div>
      </div>

      <h3 className={classNames(classes.subTitle, classes.mt25)}>Drop items</h3>
      {campaign.nfts && campaign.nfts.length > 0 ? (
        <Items
          items={campaign.nfts}
          campaign={campaign}
          getCampaignDetail={fetchCampaignDetail}
          disabled={campaignStatus !== 'DRAFT'}
        />
      ) : (
        <p className={classes.empty}>Don&apos;t have any drop items</p>
      )}

      <h3 className={classes.subTitle}>Artist</h3>

      <div className={classes.row}>
        <div className={classes.picture}>
          <p className={classes.label}>
            Profile Picture
            <span className={classes.required}>*</span>
          </p>
          <Field
            name='artist_avatar'
            component={DropzonUploaderField}
            accept='image/*'
            imageOnly
            disabled={campaignStatus !== 'DRAFT'}
          />
        </div>
        <div className={classes.about}>
          <p className={classes.label}>About the Artist</p>
          <Field
            name='artist_name'
            label='Artist’s Name'
            component={InputField}
            required
            disabled={campaignStatus !== 'DRAFT'}
            maxLength={50}
          />
          <Field
            name='artist_description'
            label='Description'
            component={TextAreaField}
            required
            disabled={campaignStatus !== 'DRAFT'}
            maxLength={500}
          />
        </div>
      </div>

      <div className={classes.actions}>
        <Button
          className='btn btnLarge btnMain'
          loading={loading}
          type='submit'
          disabled={campaignStatus !== 'DRAFT'}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

const validate = (values) => {
  const errors = {};

  if (!values.banner_image || !values.banner_image.src) {
    errors.banner_image = 'Please upload banner image';
  }

  if (!values.box_image || !values.box_image.src) {
    errors.box_image = 'Please upload box image';
  }

  if (!values.title || !values.title.trim()) {
    errors.title = 'Please enter campaign name';
  } else if (values.title.length < 5) {
    errors.title = 'Please enter campaign name min 5 characters';
  }

  if (!values.slug || !values.slug.trim()) {
    errors.slug = 'Please enter slug';
  }

  if (!values.content || !values.content.trim()) {
    errors.content = 'Please enter campaign description';
  } else if (values.content.length < 5) {
    errors.content = 'Please enter campaign description min 5 characters';
  }

  const currentDate = moment().format('YYYY-MM-DD HH:mm');
  const openEndTime = moment(values.open_end_time, 'MM/DD/YYYY HH:mm').format(
    'YYYY-MM-DD HH:mm',
  );
  if (!values.open_end_time) {
    errors.open_end_time = 'Please select automatically open all box on';
  } else if (currentDate > openEndTime) {
    errors.open_end_time =
      'Please select automatically open all box on > current time ';
  }

  if (!values.second_royality_fee) {
    errors.second_royality_fee = 'Please enter secondary royalty';
  }

  if (!values.artist_name) {
    errors.artist_name = 'Please enter artist name';
  } else if (values.artist_name.length < 5) {
    errors.artist_name = 'Please enter artist name min 5 characters';
  }

  // if (!values.total_supply) {
  //   errors.total_supply = 'Please enter total number of box';
  // }

  if (!values.artist_avatar || !values.artist_avatar?.src) {
    errors.artist_avatar = 'Please select avatar';
  }

  if (!values.artist_description) {
    errors.artist_description = 'Please enter description';
  } else if (values.artist_description.length < 5) {
    errors.artist_description = 'Please enter description min 5 characters';
  }

  return errors;
};

export default reduxForm({
  form: 'CampaignDetail',
  validate,
})(CampaignDetail);
