import React from 'react';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import 'cleave.js/dist/addons/cleave-phone.vi';
import moment from 'moment';
import calendarIcon from 'images/calendar.svg';
import DateTime from 'react-datetime';
import closeIcon from 'images/close.svg';
// import Cleave from 'cleave.js/react';
import renderField from '../../Form/renderField';
import classes from './DatePickerField.module.scss';

const MONTHS = {
  0: 'Jan',
  1: 'Feb',
  2: 'Mar',
  3: 'Apr',
  4: 'May',
  5: 'Jun',
  6: 'Jul',
  7: 'Aug',
  8: 'Sep',
  9: 'Oct',
  10: 'Nov',
  11: 'Dec',
};

class DatePickerFieldComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      openDatePicker: false,
      date: props.viewDate,
    };
  }

  moveUpPlaceholder = () => {
    const { input } = this.props;
    if (input && input.onFocus) {
      input.onFocus();
    }
    this.setState({
      focus: true,
      openDatePicker: true,
    });
  };

  moveDownPlaceholder = () => {
    const { input } = this.props;
    if (input && input.onBlur) {
      input.onBlur();
    }
    this.setState({
      focus: false,
    });
  };

  handleCancel = () => {
    this.setState({
      openDatePicker: false,
    });
  };

  handleChangeDate = (date) => {
    const { input, timeFormat } = this.props;
    if (timeFormat) {
      input.onChange(moment(date).format(`YYYY-MM-DD ${timeFormat}`));
    } else {
      input.onChange(moment(date).format('YYYY-MM-DD'));
      this.setState({
        openDatePicker: false,
      });
    }

    this.setState({
      // openDatePicker: false,
      date,
    });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.setState({
        openDatePicker: false,
      });
    }
  };

  validation = (currentDate) => {
    const { minDate, maxDate } = this.props;
    return (
      (!minDate || currentDate.isAfter(moment(minDate))) &&
      (!maxDate || currentDate.isBefore(moment(maxDate)))
    );
  };

  renderMonth = (props, month) => <td {...props}>{MONTHS[month]}</td>;

  handleBlurDateTime = () => {
    this.setState({
      openDatePicker: false,
    });
  };

  handleClickOutside = () => {
    this.setState({
      openDatePicker: false,
    });
  };

  render() {
    const {
      input,
      name,
      type,
      customClass,
      fullBorder,
      label,
      viewMode,
      viewDate,
      hasError,
      h50,
      disabled,
      timeFormat = false,
      placeholder,
    } = this.props;
    const { focus, openDatePicker, date } = this.state;

    return (
      <div
        className={classNames(
          classes.inputContainer,
          disabled && classes.containerDisabled,
        )}
      >
        {/* <Cleave
          label={label}
          onChange={(e) => {
            console.log(e.target.value);
            input.onChange(e.target.value);
          }}
          value={input.value}
          name={name}
          className={classNames(
            'form-control',
            classes.input,
            fullBorder && classes.fullBorder,
            customClass,
            hasError && classes.errorField,
            focus && classes.focus,
            h50 && classes.h50,
          )}
          type={type}
          // disabled={openDatePicker}
          onFocus={this.moveUpPlaceholder}
          onBlur={this.moveDownPlaceholder}
          placeholder='MM/DD/YYYY HH:mm'
          options={{
            datetime: true,
            datePattern: ['d', 'm', 'Y', 'H', 'm'],
          }}
          onKeyDown={this.handleKeyDown}
        /> */}
        <input
          label={label}
          onChange={(e) => {
            input.onChange(e.target.value);
          }}
          value={
            input.value &&
            input.value.length === `MM/DD/YYYY ${timeFormat}`.length
              ? moment(input.value).format(`MM/DD/YYYY ${timeFormat}`)
              : input.value
          }
          name={name}
          className={classNames(
            'form-control',
            classes.input,
            fullBorder && classes.fullBorder,
            customClass,
            hasError && classes.errorField,
            focus && classes.focus,
            h50 && classes.h50,
            disabled && classes.disabled,
          )}
          type={type}
          // disabled={openDatePicker}
          onFocus={this.moveUpPlaceholder}
          onBlur={this.moveDownPlaceholder}
          placeholder={placeholder || `MM/DD/YYYY ${timeFormat}`}
          onKeyDown={this.handleKeyDown}
        />
        {input.value && !disabled && (
          <a
            className={classes.btnClear}
            onClick={() => {
              input.onChange(null);
            }}
          >
            <img
              src={closeIcon}
              className={classes.closeIcon}
              alt='closeIcon'
            />
          </a>
        )}

        <img
          src={calendarIcon}
          className={classes.calendarIcon}
          alt='calendar'
        />
        <div className={classes.dateTimePicker}>
          <DateTime
            timeFormat={timeFormat}
            open={openDatePicker}
            input={false}
            value={moment(input.value).toDate()}
            onChange={this.handleChangeDate}
            viewMode={viewMode || 'years'}
            viewDate={date || viewDate}
            locale='en'
            isValidDate={this.validation}
            renderMonth={this.renderMonth}
            onBlur={this.handleBlurDateTime}
            disableCloseOnClickOutside={false}
          />
        </div>
      </div>
    );
  }
}

export const DatePickerField = onClickOutside(DatePickerFieldComponent);

export default renderField(DatePickerField);
