export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const STATUS = [
  {
    label: 'Active',
    value: 'ACTIVE',
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
];

export const STATUS_OBJ = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const CAMPAIGN_STATUS = [
  {
    label: 'Draft',
    value: 'DRAFT',
  },
  {
    label: 'Finish',
    value: 'FINISH',
  },
];

export const CAMPAIGN_STATUS_OBJ = {
  DRAFT: 'Draft',
  FINISH: 'Finish',
};

export const NUMBER_PER_PAGES = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 20,
    label: 20,
  },
  {
    value: 30,
    label: 30,
  },
  {
    value: 50,
    label: 50,
  },
];

export const REPORT_TIMES = [
  {
    label: 'Last 24 hours',
    value: 'LAST_24_HOURS',
  },
  {
    label: 'Last 7 days',
    value: 'LAST_7_DAYS',
  },
  {
    label: 'Last 30 days',
    value: 'LAST_30_DAYS',
  },
  {
    label: 'Last year',
    value: 'LAST_YEAR',
  },
  {
    label: 'Custom Range',
    value: 'CUSTOM_RANGE',
  },
];

export const REPORT_TIMES_OBJ = {
  LAST_24_HOURS: 'Last 24 hours',
  LAST_7_DAYS: 'Last 7 days',
  LAST_30_DAYS: 'Last 30 days',
  LAST_YEAR: 'Last year',
  CUSTOM_RANGE: 'Custom Range',
};

export const SALE_TYPES = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Fixed price',
    value: '0',
  },
  {
    label: 'Timed Auction',
    value: '1',
  },
  {
    label: 'Loot Box',
    value: '2',
  },
];

export const SALE_TYPES_OBJ = {
  0: 'Fixed price',
  1: 'Timed Auction',
  2: 'Loot Box',
};

export const TYPES = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Primary sales (box)',
    value: '1',
  },
  {
    label: 'Secondary sales box',
    value: '2',
  },
  {
    label: 'Secondary sales item',
    value: '3',
  },
];

export const TYPES_OBJ = {
  1: 'Primary sales (box)',
  2: 'Secondary sales box',
  3: 'Secondary sales item',
};

export const WALLETS = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Treasure Wallet',
    value: 'TREASURE_WALLET',
  },
  {
    label: 'User’s wallet',
    value: 'USER_WALLET',
  },
];

export const WALLETS_OBJ = {
  TREASURE_WALLET: 'Treasure Wallet',
  USER_WALLET: 'User’s wallet',
};

export const EXPORT_FILE_TYPES = [
  {
    label: 'CSV',
    value: 'CSV',
  },
];

export const USER_ROLES = [
  {
    label: 'Admin',
    value: 'ADMIN',
  },
  {
    label: 'Campaign Manager',
    value: 'CAMPAIGN_MANAGER',
  },
  {
    label: 'Minting Manager',
    value: 'MINTING_MANAGER',
  },
  {
    label: 'Finance Manager',
    value: 'FINANCE_MANAGER',
  },
  {
    label: 'Verified Creator',
    value: 'VERIFIED_CREATOR',
  },
];

export const USER_ROLES_OBJ = {
  ADMIN: 'Admin',
  CAMPAIGN_MANAGER: 'Campaign Manager',
  MINTING_MANAGER: 'Minting Manager',
  FINANCE_MANAGER: 'Finance Manager',
  VERIFIED_CREATOR: 'Verified Creator',
};

export const COLORS_BY_LEVEL = [
  '#FFD148',
  '#FC51FF',
  '#61C4F7',
  '#00E742',
  '#D8D8D8',
];

export const COLOR_MAPPING = {
  yellow: '#FFD148',
  pink: '#FC51FF',
  blue: '#61C4F7',
  green: '#00E742',
  gray: '#D8D8D8',
};

export const CURRENCIES = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'SPO',
    value: 'spo',
  },
  {
    label: 'USDT/USDC/BUSD',
    value: 'usdt',
  },
];

export const CURRENCIES_OBJ = {
  spo: 'SPO',
  usdt: 'USDT/USDC/BUSD',
};

export const SALE_STATUS = [
  {
    label: 'All',
    value: '',
  },
  // {
  //   label: 'Pending',
  //   value: 1,
  // },
  {
    label: 'Failure',
    value: 2,
  },
  {
    label: 'Success',
    value: 3,
  },
];

export const SALE_STATUS_OBJ = {
  1: 'Pending',
  2: 'Failure',
  3: 'Success',
};
