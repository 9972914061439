import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'react-bootstrap';
import defaultAvatarIcon from 'images/defaultAvatar.svg';
import classNames from 'classnames';
import logoIcon from 'images/logo-main.svg';
import history from 'utils/history';
import { createStructuredSelector } from 'reselect';
import { makeSelectProfile } from 'layout/AdminLayout/selectors';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classes from './Header.module.scss';
import MenuButton from './MenuButton';

const mapStateToProps = createStructuredSelector({
  profile: makeSelectProfile(),
});

const Header = ({
  collapse,
  showMenuClass,
  handleShowMenuMobile,
  handleHideMenu,
}) => {
  const { profile } = useSelector(mapStateToProps);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push('/auth/login');
  };

  const toggleMenu = () => {
    if (showMenuClass) {
      handleHideMenu();
    } else {
      handleShowMenuMobile();
    }
  };

  return (
    <div className={classes.container}>
      <NavLink to='/admin/campaign'>
        <div
          className={classNames(classes.left, collapse && classes.leftCollapse)}
        >
          <img className={classes.logo} src={logoIcon} alt='logo' />
        </div>
      </NavLink>
      <div className={classes.right}>
        <NavLink
          to='/admin/campaign'
          className={classNames(classes.link)}
          activeClassName={classes.active}
        >
          <span>Campaigns</span>
        </NavLink>
        <NavLink
          to='/admin/sale-summary'
          className={classNames(classes.link)}
          activeClassName={classes.active}
        >
          <span>Sales Summary</span>
        </NavLink>
        <Dropdown>
          <Dropdown.Toggle
            variant='success'
            id='dropdown-basic'
            as={React.forwardRef(({ onClick }, ref) => (
              <div
                className={classes.userName}
                ref={ref}
                onClick={(e) => {
                  e.preventDefault();
                  onClick(e);
                }}
              >
                <img
                  className={classes.avatar}
                  src={defaultAvatarIcon}
                  alt='avatar'
                />
                <div className={classes.info}>
                  <p className={classes.name}>{profile?.fullName}</p>
                  <p className={classes.accountType}>Admin</p>
                </div>
              </div>
            ))}
          />

          <Dropdown.Menu>
            <Dropdown.Item>
              <span
                className={classes.dropdownItem}
                onClick={() => {
                  history.push('/admin/profile');
                }}
              >
                Profile
              </span>
            </Dropdown.Item>
            <Dropdown.Item>
              <span
                className={classes.dropdownItem}
                onClick={() => {
                  history.push('/admin/change-password');
                }}
              >
                Change password
              </span>
            </Dropdown.Item>
            <Dropdown.Item>
              <span className={classes.dropdownItem} onClick={handleLogout}>
                <FormattedMessage id='Header.logout' defaultMessage='Logout' />
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className={classes.headerMenu}>
        <MenuButton handleToggleMenu={toggleMenu} active={showMenuClass} />

        <img src={logoIcon} className={classes.logoIcon} alt='logo' />

        <a className={classes.logout} onClick={handleLogout}>
          Logout
        </a>
      </div>
    </div>
  );
};

export default Header;
